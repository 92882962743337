import React from 'react';
import '../../App.css';
import Particle from '../Particle';
import ProjectText from '../ProjectText';
function Projects(){
  return (
    <>
      <Particle/>
      <ProjectText/>
    </>





  );
}

export default Projects;
